import * as React from 'react';
import { useFormSelector } from 'state/form/formSlice';
import { useAppNavigate } from 'state/hooks';

import { OrdersService } from 'core/api';
import { NGB_INTEGRATION_CODES, ORDERS_URL } from 'core/constants';
import { useFormWaiverHelpers } from 'state/hooks/useFormWaiverHelpers';
import { Loader } from '@teamsnap/teamsnap-ui';
import { discountsFromLocalStorage } from 'components/Form/Discounts/DiscountStorage';

export const PaymentPage = () => {
  const form = useFormSelector();
  const navigate = useAppNavigate();
  const { cartEmpty, hasCompletedWaivers } = useFormWaiverHelpers();

  const hasHockeyCanadaIntegration = form?.ngbIntegrations?.find(
    (integration) => integration.ngbIntegration.code === NGB_INTEGRATION_CODES.hockey_canada
  );

  React.useEffect(() => {
    if (!form || hasCompletedWaivers === undefined) return;

    // if payment url is accessed directly need to verify waivers are completed
    // If waivers are completed or there are no waivers, proceed with order creation
    if (hasCompletedWaivers === true || form.waivers.length === 0) {
      // all waivers are signed
      const couponCodes = discountsFromLocalStorage(form.id, null);
      OrdersService.createOrder({ formId: form.id, couponCodes: couponCodes.join(',') })
        .then((order) => {
          if (order.id) {
            const orderHref =
              order.status === 'no_fees'
                ? `${ORDERS_URL}/order/${order.id}/confirmation`
                : `${ORDERS_URL}/order/${order.id}`;

            window.location.href = orderHref;
          } else {
            navigate(`/form/${form?.id}/cart`);
          }
        })
        .catch((e) => {
          navigate(`/form/${form?.id}/cart${e.status === 400 ? '' : '?error=true'}`);
        });
    } else {
      // not all waivers are signed
      navigate(`/form/${form?.id}/agreements`);
    }
  }, [hasCompletedWaivers, form]);

  React.useEffect(() => {
    if (!form) return;
    if (cartEmpty) {
      // redirect to cart for now if nothing to pay for
      navigate(`/form/${form?.id}/cart`);
    }
  }, [cartEmpty]);

  return <Loader type="spin" text="Loading..." />;
};
